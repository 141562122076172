<template>
  <b-form-group>
    <div slot="label" class="d-flex align-items-baseline">
      <span class="text-capitalize">{{ field.label || field.name }}</span>
      <b-form-radio-group
        buttons
        size="sm"
        button-variant="outline-secondary"
        :checked="operator"
        :options="operations"
        @input="$emit('update:operator', $event)"
        class="ml-auto mb-0 mr-2"
      />
      <b-button size="sm" variant="outline-secondary" @click="$emit('remove')">
        &times;
      </b-button>
    </div>
    <multiselect
      v-if="field.type == 'select'"
      :value="dataSelected"
      @input="optionSelected"
      :options="options"
      :multiple="true"
      track-by="value"
      label="text"
    />
    <crud-multiselect
      v-else-if="field.type == 'async-select'"
      :endpoint="`/data-api/${field.display.endpoint}`"
      :trackBy="field.display.trackBy"
      :searchFields="field.display.searchFields"
      :label="field.display.name"
      :initFetchLabels="true"
      v-model="crudSelectData"
      @input="optionSelected"
    />
    <b-input v-else :value="data" @input="$emit('update:data', $event)" />
  </b-form-group>
</template>

<script>
import axios from 'axios';
import { CondOperator } from '@dataui/crud-request';
import { mapGetters } from 'vuex';
import { initSelect } from './utils';
import CrudMultiSelect from '@gid/vue-common/components/filters/CrudMultiSelect.vue';

export default {
  components: {
    'crud-multiselect': CrudMultiSelect,
  },
  props: {
    field: Object,
    operator: String,
    data: null,
  },
  data() {
    return {
      operations: [],
      options: [],
      crudSelectData: null,
    };
  },
  computed: {
    ...mapGetters(['locale']),
    dataSelected() {
      if (this.field.type == 'async-select') {
        return this.data;
      }
      return Array.isArray(this.data)
        ? this.options.filter(({ value }) => this.data.includes(value))
        : [];
    },
  },
  watch: {
    field() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.field.type == 'select') {
        this.operations = [
          { value: CondOperator.IN, text: 'Include' },
          { value: CondOperator.NOT_IN, text: 'Exclude' },
        ];
        if (this.field.display.selectOptions) {
          this.options = this.field.display.selectOptions.map((option) => ({
            value: option,
            text: option,
          }));
        } else if (this.field.display.endpoint) {
          axios
            .get(`/data-api/${this.field.display.endpoint}`)
            .then((response) => {
              this.options = initSelect(response.data, this.field, this.locale);
            })
            .catch((error) => {
              this.$emit('error', {
                data: error,
                title: 'Fetch related data failed.',
              });
            });
        }
      } else if (['int', 'number'].includes(this.field.type)) {
        this.operations = [
          { value: CondOperator.EQUALS, text: '=' },
          { value: CondOperator.NOT_IN, text: '!=' },
          { value: CondOperator.GREATER_THAN, text: '>' },
          { value: CondOperator.GREATER_THAN_EQUALS, text: '≧' },
          { value: CondOperator.LOWER_THAN, text: '<' },
          { value: CondOperator.LOWER_THAN_EQAULS, text: '≦' },
        ];
      } else {
        this.operations = [
          { value: CondOperator.CONTAINS, text: 'Contains' },
          { value: CondOperator.STARTS, text: 'Starts' },
          { value: CondOperator.ENDS, text: 'Ends' },
          { value: CondOperator.EXCLUDES, text: 'Excludes' },
        ];
      }

      if (this.operator == null && this.operations.length > 0) {
        this.$emit('update:operator', this.operations[0].value);
      }
    },
    optionSelected(event) {
      if (this.field.type == 'async-select') {
        if (event.translations?.length && event.translations?.[0]?.headline)
          this.$emit('update:data', event.translations?.[0]?.headline);
        else {
          let fieldName = this.field.name;
          if (Array.isArray(this.field.name)) {
            fieldName = this.field.name[1];
          }
          this.$emit('update:data', event[fieldName]);
        }
      } else {
        this.$emit(
          'update:data',
          event.map(({ value }) => value),
        );
      }
    },
  },
};
</script>
