<template>
  <div v-if="entityDefinition.basePath">
    <div class="d-flex align-items-center py-3">
      <h1 class="h3 mr-3 mb-0">{{ entityDefinition.title.plural }}</h1>
      <b-button class="mr-2" variant="primary" @click="createItem">
        Create New
      </b-button>
      <b-checkbox v-model="useFilters">Filter results</b-checkbox>
      <b-dropdown
        v-if="exportImportAvailable"
        size="sm"
        text="Export / Import"
        class="m-2"
      >
        <b-dropdown-group header="Export with Single Relations">
          <b-dropdown-item-button @click="exportCurrentScreen('csv')">
            Export .csv
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="exportCurrentScreen('xlsx')">
            Export .xls
          </b-dropdown-item-button>
        </b-dropdown-group>
        <b-dropdown-group header="Export with All Relations">
          <b-dropdown-item-button
            @click="exportCurrentScreen('csv-all-relations')"
          >
            Export .csv
          </b-dropdown-item-button>
          <b-dropdown-item-button
            @click="exportCurrentScreen('xlsx-all-relations')"
          >
            Export .xls
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="exportCurrentScreen('yaml-with-pk')">
            Export .yaml for update (with ids)
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="exportCurrentScreen('yaml-no-pk')">
            Export .yaml for create (w/o ids)
          </b-dropdown-item-button>
          <b-dropdown-item-button
            @click="exportCurrentScreen('yaml-fixture-with-pk')"
          >
            Export Fixture with ids
          </b-dropdown-item-button>
          <b-dropdown-item-button
            @click="exportCurrentScreen('yaml-fixture-no-pk')"
          >
            Export Fixture w/o ids
          </b-dropdown-item-button>
        </b-dropdown-group>
        <b-dropdown-group header="Import">
          <b-dropdown-item-button @click="importFileModal()">
            Import file ...
          </b-dropdown-item-button>
        </b-dropdown-group>
      </b-dropdown>
    </div>
    <crud-list
      ref="list"
      :entity="entityDefinition"
      :primary-key-name="primaryKeyName"
      :use-filters="useFilters"
      @edit="editItem"
      @clone="cloneItem"
      @delete="deleteItem"
      @detail="detailItem"
      @error="renderError($event.data, $event.title)"
      :current-screen-url.sync="currentScreenUrl"
    />
    <b-modal
      ref="modal"
      size="huge"
      :title="modal.title"
      @ok.prevent="okAction()"
    >
      <crud-single
        ref="form"
        :entity="entityDefinition"
        :primary-key="modal.pk"
        :proto="modal.proto"
        :detail="modal.detail"
        hide-buttons
        @done="itemSaved"
        @error="renderError($event.data, $event.title)"
      />
    </b-modal>
    <importer-modal
      ref="importModal"
      :current-screen-url="currentScreenUrl"
      @error="renderError($event.data, $event.title)"
      @close="$refs.list.refresh()"
    />
  </div>
  <div v-else-if="entityDefinition.basePath == null">
    <b-spinner variant="secondary" class="m-2" />
  </div>
  <div v-else>
    <h1 class="my-5 display-4 text-center text-muted">Entity not found</h1>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { mapGetters } from 'vuex';

import Single from '@/components/crud/Single.vue';
import List from '@/components/crud/List.vue';
import ImporterModal from '../importer/ImporterModal.vue';

export default {
  components: {
    'crud-single': Single,
    'crud-list': List,
    'importer-modal': ImporterModal,
  },
  props: {
    entity: String,
  },
  data() {
    return {
      entityDefinition: {
        title: {
          singular: 'Object',
          plural: 'Objects',
        },
        basePath: null,
        fields: [],
        actions: [],
        delete: {
          strategy: 'hard',
        },
      },
      modal: {
        title: 'Title',
        pk: null,
        proto: null,
        detail: false,
      },
      useFilters: false,
      currentScreenUrl: {},
    };
  },
  watch: {
    entity(newValue) {
      this.useFilters = false;
      this.loadEntity(newValue);
    },
  },
  computed: {
    ...mapGetters(['access_token']),
    primaryKeyName() {
      const field = this.entityDefinition.fields.find(({ primary }) => primary);
      return field ? field.name : null;
    },
    exportImportAvailable() {
      const noUsageList = [
        'identification',
        'form',
        'form-settings',
        'order-form-question',
        'form-opportunity',
        'form-opportunity-protocol',
        'form-flow',
        'flow',
        'answer',
      ];
      if (noUsageList.includes(this.entityDefinition.basePath)) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.loadEntity(this.entity);
  },
  methods: {
    loadEntity(entity) {
      const { default: entities } = require(`@/entities`);
      this.entityDefinition = entities[entity] || {
        basePath: null,
      };
    },
    createItem() {
      this.modal.title = `Create ${this.entityDefinition.title.singular}`;
      this.modal.pk = null;
      this.modal.proto = null;
      this.modal.detail = false;
      this.$refs.modal.show();
    },
    editItem(item) {
      this.modal.title = `Update ${this.entityDefinition.title.singular}`;
      this.modal.pk = item[this.primaryKeyName];
      this.modal.proto = null;
      this.modal.detail = false;
      this.$refs.modal.show();
    },
    cloneItem(item) {
      this.modal.title = `Create ${this.entityDefinition.title.singular}`;
      this.modal.pk = null;
      this.modal.proto = item[this.primaryKeyName];
      this.modal.detail = false;
      this.$refs.modal.show();
    },
    detailItem(item) {
      this.modal.title = `Detail ${this.entityDefinition.title.singular}`;
      this.modal.pk = item[this.primaryKeyName];
      this.modal.proto = null;
      this.modal.detail = true;
      this.$refs.modal.show();
    },
    deleteItem(item) {
      const id = item[this.primaryKeyName];
      if (confirm('Are you sure you want to delete this record?')) {
        const hardDelete =
          this.entityDefinition.delete?.strategy === 'soft' ? false : true;
        if (hardDelete) {
          axios
            .delete(
              `/data-api/${this.entityDefinition.basePath}/${id}`,
              this.toSave,
            )
            .then(() => {
              this.$refs.list.refresh();
            })
            .catch((error) => {
              this.renderError(error, 'Deletion failed');
            });
        } else {
          axios
            .delete(`/data-api/${this.entityDefinition.basePath}/${id}`)
            .then(() => {
              this.$refs.list.refresh();
            })
            .catch((error) => {
              this.renderError(error, 'Soft Deletion failed');
            });
        }
      }
    },
    okAction() {
      if (this.modal.detail) {
        this.$refs.form.closeAction();
      } else {
        this.$refs.form.saveModel();
      }
    },
    itemSaved() {
      this.$refs.modal.hide();
      this.$refs.list.refresh();
    },
    errorMessage(error) {
      return _.get(error, 'response.data.errorCode') == '23503'
        ? 'Maybe object is referenced by other items?'
        : 'Something went wrong. Have a look in error details below for more information.';
    },
    renderError(error, title) {
      const h = this.$createElement;
      const contents = [
        h('p', this.errorMessage(error)),
        h(
          'b-button',
          {
            props: {
              variant: 'outline-danger',
              size: 'sm',
            },
            on: {
              click: () => {
                this.showErrorDetails(error);
              },
            },
          },
          'Error details',
        ),
      ];
      this.$bvToast.toast(contents, {
        title,
        variant: 'danger',
      });
    },
    showErrorDetails(error) {
      const errData =
        error?.request && error?.response
          ? {
              request: error.request,
              response: error.response,
            }
          : error;
      const contents = [
        this.$createElement('pre', JSON.stringify(errData, null, '  ')),
      ];
      this.$bvModal.msgBoxOk(contents, {
        title: 'Error details',
        size: 'lg',
        centered: true,
      });
    },
    exportCurrentScreen(format) {
      const path = `/data-api/export/${format}/${this.currentScreenUrl.entityPath}?${this.currentScreenUrl.noPagingQueryString}&jwt=${this.access_token}`;
      window.open(path);
    },
    importFileModal() {
      this.$refs.importModal.open();
    },
  },
};
</script>
<style lang="scss">
@media (min-width: 992px) {
  .modal .modal-huge {
    max-width: 90% !important;
    width: 90% !important;
    max-height: 90% !important;
    height: 90% !important;
  }
}
</style>
