var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.model),function(field,index){return _c('b-form-group',{key:index,attrs:{"label":field.label,"description":field.description,"label-class":"text-capitalize"}},[(field.i18n)?_c('b-tabs',{attrs:{"pills":"","small":"","content-class":"mt-2"}},_vm._l((field.data),function(locale){return _c('b-tab',{key:locale.locale,attrs:{"title":locale.locale}},[_c(field.component.tag,_vm._g(_vm._b({tag:"component",model:{value:(locale.data),callback:function ($$v) {_vm.$set(locale, "data", $$v)},expression:"locale.data"}},'component',field.component.options,false),field.component.eventHandlers)),(field.preview)?_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Preview "),_c('b-button',{staticClass:"my-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.loadPreview(field, locale)}}},[_vm._v(" load "),(locale.preview.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1),_vm._v(" : ")]},proxy:true}],null,true)},[(locale.preview.url)?_c('iframe',{class:[
              'border',
              'w-100',
              { 'border-danger': locale.preview.error },
            ],staticStyle:{"height":"50vh"},attrs:{"src":locale.preview.url}}):_vm._e()]):_vm._e()],1)}),1):[_c(field.component.tag,_vm._g(_vm._b({tag:"component",attrs:{"readonly":_vm.isFieldReadonly(field),"disabled":_vm.isFieldReadonly(field)},model:{value:(field.data),callback:function ($$v) {_vm.$set(field, "data", $$v)},expression:"field.data"}},'component',field.component.options,false),field.component.eventHandlers))]],2)}),(!_vm.hideButtons)?_c('div',[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.saveModel}},[_vm._v(" Save ")])],1):_vm._e(),(
      _vm.detail &&
      _vm.actionDetail &&
      _vm.actionDetail.extra &&
      _vm.actionDetail.extra.actions
    )?[(_vm.loading)?_c('b-spinner',{attrs:{"variant":"secondary"}}):_vm._l((_vm.actionDetail.extra.actions),function(action,actionIndex){return _c('b-button',{key:actionIndex + action.type,staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.extraAction($event, action.type)}}},[_vm._v(" "+_vm._s(action.label)+" "),_c('i',{staticClass:"material-icons",domProps:{"textContent":_vm._s(action.icon)}})])})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }