<template>
  <MonacoEditor
    class="border"
    :height="272"
    :value="value"
    @input="$emit('input', $event)"
    :language="language"
    :options="{
      automaticLayout: true,
      minimap: {
        enabled: false,
      },
    }"
  />
</template>

<script>
import MonacoEditor from '@gid/vue-common/components/MonacoEditor';

export default {
  components: {
    MonacoEditor,
  },
  props: {
    value: String,
    language: {
      type: String,
      default: 'javascript',
    },
  },
};
</script>

<style lang="scss">
.gid-code-input {
  font-size: 0.8rem;
}
</style>
