<template>
  <div>
    <b-card v-if="useFilters" bg-variant="light" class="my-3">
      <b-row>
        <b-col md="6" v-for="(filter, index) in filters" :key="index">
          <list-filter
            :field="filter.field"
            :operator.sync="filter.operator"
            :data.sync="filter.data"
            @remove="removeFilter(index)"
            @error="this.$emit('error', $event)"
          />
        </b-col>
      </b-row>
      <b-dropdown text="Add Filter" class="align-baseline">
        <b-dropdown-item
          v-for="(field, index) in entity.fields"
          :key="index"
          @click="addFilter(field)"
          class="text-capitalize"
        >
          {{ field.label || field.name }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card>
    <b-table
      ref="table"
      v-if="!dirty"
      show-empty
      striped
      stacked="md"
      :items="itemsProvider"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDir"
    >
      <template #cell(_actions)="{ item }">
        <b-button @click="$emit('edit', item)" size="sm" class="mr-1">
          <i class="material-icons">edit</i>
        </b-button>
        <b-button @click="$emit('clone', item)" size="sm" class="mr-1">
          <i class="material-icons">control_point_duplicate</i>
        </b-button>
        <b-button @click="$emit('delete', item)" size="sm" class="mr-1">
          <i class="material-icons">delete</i>
        </b-button>
        <template v-if="entity.actions">
          <b-button
            v-for="(action, actionIndex) in entity.actions"
            :key="actionIndex + action.type"
            @click="$emit(action.type, item)"
            size="sm"
            class="mr-1"
          >
            <i class="material-icons" v-text="action.icon"></i>
          </b-button>
        </template>
      </template>
    </b-table>
    <b-pagination
      :total-rows="totalRows"
      :per-page="perPage"
      v-model="currentPage"
      align="center"
    />
    <div v-if="currentPageCount" class="text-center text-muted">
      <small>{{ currentPageCount }} / {{ totalRows }}</small>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { RequestQueryBuilder } from '@dataui/crud-request';
import { mapGetters } from 'vuex';

import ListFilter from './ListFilter.vue';

export default {
  components: {
    ListFilter,
  },
  props: {
    entity: Object,
    primaryKeyName: String,
    useFilters: Boolean,
  },
  data() {
    return {
      currentPage: 1,
      currentPageCount: null,
      perPage: 10,
      totalRows: 0,
      sortBy: null,
      sortDir: true,
      dirty: false,
      filters: [],
    };
  },
  watch: {
    entity: {
      deep: true,
      handler() {
        this.currentPage = 1;
        this.sortBy = this.primaryKeyName;
        this.sortDir = true;
        this.filters = [];
        // Fix bootstrap table fields/data sync issue
        this.dirty = true;
        this.$nextTick(() => {
          this.dirty = false;
        });
      },
    },
    filters: {
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  computed: {
    ...mapGetters(['locale']),
    tableFields() {
      const fields = this.entity.fields
        .filter((field) => !field.hideInList)
        .map((field) => ({
          label: field.label || field.name,
          key: field.relation
            ? `${field.relation.name}.${field.name}`
            : field.name,
          sortable: !field.relation,
          thClass: 'text-capitalize',
          formatter: (value, key, item) => {
            const result = this.getLocalized(field, item);
            return Array.isArray(result) ? result.join(', ') : result;
          },
        }));
      fields.push({
        label: 'Actions',
        key: '_actions',
        class: 'text-right text-nowrap',
      });
      return fields;
    },
  },
  created() {
    let sortBy = this.primaryKeyName;
    let sortDir = 'DESC';
    const primaryKeyField = this.entity.fields.find(
      (x) => x.name === this.primaryKeyName && x.primary,
    );
    if (primaryKeyField && primaryKeyField.type === 'uuid') {
      sortBy = this.entity.sort?.name || null;
      sortDir = this.entity.sort?.direction || 'DESC';
    }
    this.sortBy = sortBy;
    this.sortDir = sortDir;
  },
  methods: {
    itemsProvider(ctx) {
      const query = {
        sort: !this.sortBy
          ? null
          : [{ field: this.sortBy, order: this.sortDir ? 'DESC' : 'ASC' }],
        page: ctx.currentPage,
        limit: ctx.perPage,
      };
      if (this.useFilters && this.filters.length > 0) {
        query.filter = this.filters
          .filter(
            ({ data }) => data && (Array.isArray(data) ? data.length : true),
          )
          .map((filter) => ({
            field: filter.field.relation
              ? `${filter.field.relation.name}.${
                  Array.isArray(filter.field.name)
                    ? filter.field.name[1]
                    : filter.field.name
                }`
              : filter.field.name,
            operator: filter.operator,
            value: filter.data,
          }));
      }
      const queryString = RequestQueryBuilder.create(query).query();
      const noPagingQuery = {
        ...query,
        page: undefined,
        limit: undefined,
      };
      const noPagingQueryString =
        RequestQueryBuilder.create(noPagingQuery).query();
      this.$emit('update:currentScreenUrl', {
        query,
        queryString,
        noPagingQuery,
        noPagingQueryString,
        entityPath: this.entity.basePath,
      });

      return axios
        .get(`/data-api/${this.entity.basePath}/?${queryString}`)
        .then((response) => {
          this.totalRows = response.data.total;
          this.currentPageCount = response.data.count;
          return response.data.data;
        })
        .catch((error) => {
          this.$emit('error', { data: error, title: 'Listing failed' });
        });
    },
    refresh() {
      this.$refs.table.refresh();
    },
    getLocalized(field, item) {
      if (!field.relation) {
        return _.get(
          item,
          field.i18n ? `${field.name}_${this.locale}` : field.name,
          '',
        );
      } else {
        if (field.i18n) {
          if (field.relation.type == 'many') {
            const locale = item[field.relation.name].find(
              ({ locale }) => locale == this.locale,
            );
            return locale ? locale[field.name] : '-';
          } else if (field.relation.type == 'one') {
            return item[field.relation.name][
              `${Array.isArray(field.name) ? field.name[1] : field.name}_${
                this.locale
              }`
            ];
          }
        } else {
          if (field.display) {
            if (field.relation.type == 'many') {
              const displayItems = item[field.relation.name];
              return displayItems.map((displayItem) => {
                return this.getLocalized(field.display, displayItem);
              });
            } else if (field.relation.type == 'one') {
              return this.getLocalized(
                field.display,
                item[field.relation.name],
              );
            }
          }
        }
      }
    },
    addFilter(field) {
      this.filters.push({
        field,
        operator: null,
        data: null,
      });
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
    },
  },
};
</script>
