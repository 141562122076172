export function initSelect(data, field, lang) {
  return data.map((item) => {
    let label;
    if (field.display.i18n) {
      if (field.display.relation) {
        if (field.display.relation.type == 'many') {
          const locale = item[field.display.relation.name].find(
            ({ locale }) => locale == lang,
          );
          label = locale ? locale[field.display.name] : '(not-set)';
        } else if (field.display.relation.type == 'one') {
          label = item[`${field.display.name}_${lang}`];
        }
      } else {
        label = item[`${field.display.name}_${lang}`];
      }
    } else if (field.display.locale) {
      label = item[field.display.name].filter(
        (el) => el.locale === field.display.locale,
      )[0][field.display.property];
    } else {
      label = item[field.display.name];
    }

    return {
      value: item[field.name],
      text: label,
    };
  });
}
