<template>
  <div>
    <b-form-group
      v-for="(field, index) in model"
      :key="index"
      :label="field.label"
      :description="field.description"
      label-class="text-capitalize"
    >
      <b-tabs v-if="field.i18n" pills small content-class="mt-2">
        <b-tab
          v-for="locale in field.data"
          :key="locale.locale"
          :title="locale.locale"
        >
          <component
            :is="field.component.tag"
            v-bind="field.component.options"
            v-on="field.component.eventHandlers"
            v-model="locale.data"
          />
          <b-form-group v-if="field.preview">
            <template #label>
              Preview
              <b-button
                size="sm"
                @click="loadPreview(field, locale)"
                class="my-2"
              >
                load
                <b-spinner v-if="locale.preview.loading" small></b-spinner>
              </b-button>
              :
            </template>
            <iframe
              v-if="locale.preview.url"
              :src="locale.preview.url"
              :class="[
                'border',
                'w-100',
                { 'border-danger': locale.preview.error },
              ]"
              style="height: 50vh"
            ></iframe>
          </b-form-group>
        </b-tab>
      </b-tabs>
      <template v-else>
        <component
          :is="field.component.tag"
          v-bind="field.component.options"
          v-on="field.component.eventHandlers"
          v-model="field.data"
          :readonly="isFieldReadonly(field)"
          :disabled="isFieldReadonly(field)"
        />
      </template>
    </b-form-group>
    <div v-if="!hideButtons">
      <b-button variant="primary" class="mr-2" @click="saveModel">
        Save
      </b-button>
    </div>
    <template
      v-if="
        detail &&
        actionDetail &&
        actionDetail.extra &&
        actionDetail.extra.actions
      "
    >
      <b-spinner variant="secondary" v-if="loading" />
      <template v-else>
        <b-button
          v-for="(action, actionIndex) in actionDetail.extra.actions"
          :key="actionIndex + action.type"
          @click="extraAction($event, action.type)"
          variant="primary"
          size="sm"
          class="mr-1"
        >
          {{ action.label }}
          <i class="material-icons" v-text="action.icon"></i>
        </b-button>
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { initSelect } from './utils';
import CodeInput from './CodeInput';
import ArrayInput from './ArrayInput';
import { RequestQueryBuilder } from '@dataui/crud-request';
import locales from '../../i18n/locales';
import FilesSelector from '@gid/vue-common/components/inputs/FilesSelector.vue';
import CrudMultiSelect from '@gid/vue-common/components/filters/CrudMultiSelect.vue';

export default {
  components: {
    CodeInput,
    ArrayInput,
    FilesSelector,
    CrudMultiSelect,
  },
  props: {
    primaryKey: null,
    proto: null,
    detail: Boolean,
    entity: Object,
    hideButtons: Boolean,
  },
  data() {
    return {
      existingData: null,
      model: [],
      locales,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['locale', 'access_token']),
    isNew() {
      return this.primaryKey == null;
    },
    toSave() {
      return this.model.reduce((data, field) => {
        if (
          !(field.primary && ['int', 'uuid'].includes(field.type)) &&
          !field.relation
        ) {
          data[field.name] = this.sanitizeValue(field.data, field);
        } else if (field.relation) {
          if (field.array && field.emptyToNull) {
            if (Array.isArray(field.data)) {
              field.data.forEach((element, index) => {
                if (Object.keys(element).includes('locale')) {
                  if (Array.isArray(element.data) && !element.data.length) {
                    element.data = null;
                  } else if (Array.isArray(element.data)) {
                    element.data = element.data.filter((elem) => !!elem);
                  }
                }
              });
            }
          }
        }
        return data;
      }, {});
    },
    toSaveRelations() {
      const relationModelData = this.model.filter(({ relation }) => relation);
      if (relationModelData) {
        const data = {};
        relationModelData.forEach((field) => {
          if (field.i18n && field.data) {
            field.data.forEach((locale) => {
              const hasTitle = relationModelData.find(
                (field) => field.name == 'title',
              );
              const titleHasLocaleValue = hasTitle
                ? !!hasTitle.data.find(
                    (entry) => entry?.locale == locale.locale,
                  )?.data
                : false;
              if (locale.id) {
                _.set(
                  data,
                  `${field.relation.name}.$i18n.${locale.locale}.id`,
                  locale.id,
                );
              }
              if (
                !Object.keys(field).includes('i18nAllowEmpty') ||
                field.i18nAllowEmpty
              ) {
                _.set(
                  data,
                  `${field.relation.name}.$i18n.${locale.locale}.${field.name}`,
                  locale.data ?? '',
                );
              } else if (locale.data) {
                _.set(
                  data,
                  `${field.relation.name}.$i18n.${locale.locale}.${field.name}`,
                  locale.data,
                );
              } else if (!locale.data && hasTitle && titleHasLocaleValue) {
                _.set(
                  data,
                  `${field.relation.name}.$i18n.${locale.locale}.${field.name}`,
                  null,
                );
              }
            });
          } else {
            if (field.data !== undefined) {
              if (field.type == 'select') {
                if (field.relation.type == 'many') {
                  data[field.relation.name] = field.data.map(({ value }) => ({
                    [field.name]: value,
                  }));
                } else if (field.relation.type == 'one') {
                  if (field.data) {
                    _.set(
                      data,
                      `${field.relation.name}.${field.name}`,
                      field.data.value,
                    );
                  } else {
                    _.set(data, `${field.relation.name}`, null);
                  }
                }
              } else if (field.type == 'async-select') {
                if (field.data) {
                  let fieldName = Object.keys(field).includes('nameFK')
                    ? field.nameFK
                    : field.name;
                  if (Array.isArray(fieldName)) {
                    fieldName = this.isNew ? fieldName[0] : fieldName[1];
                  }
                  _.set(
                    data,
                    `${field.relation.name}.${fieldName}`,
                    field.data[field.display.trackBy],
                  );
                } else {
                  _.set(data, `${field.relation.name}`, null);
                }
              } else {
                _.set(data, `${field.relation.name}.${field.name}`, field.data);
              }
            }
          }
        });

        Object.keys(data).forEach((key) => {
          if (data[key]?.$i18n) {
            data[key] = Object.keys(data[key].$i18n).map((locale) => ({
              locale,
              ...data[key].$i18n[locale],
            }));
            delete data[key].$i18n;
          }
        });
        return data;
      } else {
        return null;
      }
    },
    actionDetail() {
      if (Object.keys(this.entity).includes('actions')) {
        return this.entity.actions.find((x) => x.type === 'detail');
      }
      return null;
    },
  },
  created() {
    if (!this.isNew || this.proto !== null) {
      this.loadModel();
    } else {
      this.initModel();
    }
  },
  destroyed() {
    // clean up memory taken by previews
    this.model
      .filter(({ i18n, preview }) => i18n && preview)
      .forEach(({ data }) => {
        data
          .filter(({ preview }) => preview.url)
          .forEach(({ preview }) => {
            URL.revokeObjectURL(preview.url);
          });
      });
  },
  methods: {
    initModel() {
      let fields = [...this.entity.fields];

      const extraFields = [];
      if (
        this.actionDetail &&
        this.actionDetail.extra &&
        this.actionDetail.extra.fields
      ) {
        this.actionDetail.extra.fields.forEach((x) => {
          if (!fields.includes(x)) {
            extraFields.push(x);
          }
        });
      }
      fields.push(...extraFields);

      this.model = fields
        .filter(
          ({ primary, type }) =>
            !primary || (primary && type !== 'int') || !this.isNew,
        )
        .map((field) => {
          const default_value =
            'default' in field
              ? field.default
              : field.array
              ? []
              : field.type === 'boolean'
              ? false
              : null;
          const input = {
            ...field,
            label: field.label ? field.label : field.name,
            component: this.fieldToComponent(field),
            data: this.loadExistingData(field, default_value),
          };
          if (field.type == 'select') {
            this.initSelectData(input, field);
          }

          return input;
        });
      this.model.forEach((input) => {
        if (input.type == 'select') {
          this.initSelectData(input, input);
        }
      });
    },
    loadModel() {
      const loadId = this.primaryKey || this.proto;
      axios
        .get(`/data-api/${this.entity.basePath}/${loadId}`)
        .then((response) => {
          this.existingData = response.data;
          this.initModel();
        })
        .catch((error) => {
          this.$emit('error', {
            data: error,
            title: 'Load model data failed',
          });
        });
    },
    getModelData() {
      return {
        ...this.toSave,
        ...this.toSaveRelations,
      };
    },
    isFieldReadonly(field) {
      if (this.detail || field.readonly) {
        return true;
      }
      return this.isNew ? false : field.primary;
    },
    extraAction(event, actionType) {
      switch (actionType) {
        case 'recreateJob':
          this.recreateJob();
          break;
        default:
          this.console.log(`No action type ${actionType} could be found.`);
      }
    },
    recreateJob() {
      this.loading = true;
      const request = axios
        .put(
          `/api/admin/job-imports/${this.existingData.id}/recreate`,
          JSON.stringify({}),
        )
        .then((data) => {
          this.loading = false;
          this.$emit('done');
        })
        .catch((error) => {
          this.loading = false;
          this.$emit('error', {
            data: error,
            title: 'Force job creation has failed.',
          });
        });
    },
    closeAction() {
      this.$nextTick(() => {
        this.$emit('done');
      });
    },
    saveModel() {
      const data = this.getModelData();
      let request;
      if (this.isNew) {
        request = axios
          .post(`/data-api/${this.entity.basePath}/`, data)
          .then(() => {
            this.$emit('done');
          });
      } else {
        request = axios
          .put(`/data-api/${this.entity.basePath}/${this.primaryKey}`, data)
          .then(() => {
            this.$emit('done');
          });
      }
      request.catch((error) => {
        this.$emit('error', { data: error, title: 'Create/Update failed.' });
      });
    },
    fieldToComponent(field) {
      switch (field.type) {
        case 'numeric': {
          const { precision, scale } = field;
          const absMax = (precision - scale) * 10 || 1;
          return {
            tag: 'b-form-input',
            options: {
              type: 'number',
              min: -absMax,
              max: absMax,
              step: 1 / 10 ** scale,
            },
          };
        }
        case 'int':
          return { tag: 'b-form-input', options: { type: 'number' } };
        case 'select':
          return { tag: 'multiselect', options: {} };
        case 'async-select':
          return {
            tag: 'CrudMultiSelect',
            options: {
              endpoint: `/data-api/${field.display.endpoint}`,
              trackBy: field.display.trackBy,
              searchFields: field.display.searchFields,
              label: field.display.name,
              initFetchLabels: true,
            },
          };
        case 'boolean':
          return { tag: 'b-form-checkbox' };
        case 'code':
          return {
            tag: 'code-input',
            options: { language: field.display?.language },
          };
        case 'textarea':
          return { tag: 'b-form-textarea', options: { 'max-rows': '6' } };
        case 'json':
          return { tag: 'code-input' };
        case 'img':
          return { tag: 'files-selector' };
        default:
          return field.array ? { tag: 'array-input' } : { tag: 'b-form-input' };
      }
    },
    loadExistingData(field, default_value = null) {
      if (field.i18n) {
        return this.loadExistingLocaleData(field, default_value);
      } else {
        let data = _.get(
          this.existingData,
          field.relation ? `${field.relation.name}.${field.name}` : field.name,
          default_value,
        );
        if (field.type == 'select' && field.relation) {
          if (field.relation.type == 'many') {
            data = _.get(this.existingData, field.relation.name, null);
            return data
              ? data.map((item) => ({ value: item[field.name] }))
              : [];
          } else if (field.relation.type == 'one') {
            return data ? { value: data } : null;
          }
        } else if (field.type == 'async-select') {
          return field?.relation?.name && this.existingData
            ? this.existingData[field?.relation?.name]
            : null;
        } else {
          if (field.type === 'select' && Array.isArray(data)) {
            data = data.map((d) => {
              if (d.split(' ').length) {
                const regexp = /['"']/g;
                return d.replace(regexp, '');
              }
              return d;
            });
          }
          if (field.type === 'json') {
            if (typeof data === 'object') {
              return JSON.stringify(data, undefined, 2);
            }
          }
          return data;
        }
      }
    },
    loadExistingLocaleData(field, default_value = null) {
      return this.locales.map((localeKey) => {
        let localeData = default_value;
        let id = null;
        if (this.existingData) {
          const localeDataItem = this.existingData[field.relation.name].find(
            ({ locale }) => localeKey == locale,
          );
          if (localeDataItem) {
            if (!this.proto) {
              id = localeDataItem.id;
            }
            localeData = localeDataItem[field.name];
          }
        }
        return {
          id,
          locale: localeKey,
          data: localeData,
          preview: {
            url: null,
            loading: false,
            error: true,
          },
        };
      });
    },
    initSelectData(input, field) {
      if (field.display.disableEmpty) {
        input.component.options.allowEmpty = false;
      }
      if (field.relation?.type == 'many' || field.display.multiple == true) {
        input.component.options.multiple = true;
      }
      if (field.display.selectOptions) {
        input.component.options.options = field.display.selectOptions;
      } else {
        input.component.options.options = [];
        if (field.display.endpoint) {
          (input.component.options.trackBy = 'value'),
            (input.component.options.label = 'text');
          switch (typeof field.display.placeholder) {
            case 'string':
              input.component.options.placeholder = field.display.placeholder;
              break;
            case 'function':
              input.component.options.placeholder = field.display.placeholder(
                this.getModelData(),
              );
              break;
          }
          const setSelected = (target) => {
            if (!target) {
              if (input.data) {
                if (field.relation.type == 'one') {
                  input.data = input.component.options.options.find(
                    ({ value }) => value == input.data.value,
                  );
                } else if (field.relation.type == 'many') {
                  const values = input.data.map(({ value }) => value);
                  input.data = input.component.options.options.filter(
                    ({ value }) => values.includes(value),
                  );
                }
              }
            } else {
              const { targetField, targetInput } = target;
              if (targetInput.data) {
                if (field.relation.type == 'one') {
                  targetInput.data = targetInput.component.options.options.find(
                    ({ value }) => value == targetInput.data.value,
                  );
                } else if (targetField.relation.type == 'many') {
                  const values = targetInput.data.map(({ value }) => value);
                  targetInput.data =
                    targetInput.component.options.options.filter(({ value }) =>
                      values.includes(value),
                    );
                }
              }
            }
          };
          const updateOptions = (queryString = '', endpoint, targetField) => {
            axios
              .get(
                `/data-api/${
                  endpoint || field.display.endpoint
                }/?${queryString}`,
              )
              .then((response) => {
                let options;
                if (!targetField) {
                  options = initSelect(response.data, field, this.locale);
                  input.component.options.options = options;
                  setSelected();
                } else {
                  options = initSelect(response.data, targetField, this.locale);
                  this.model.forEach((entity) => {
                    if (entity.display && entity.display.endpoint == endpoint) {
                      entity.component.options.options = options;
                      const target = {
                        targetInput: entity,
                        targetField: entity,
                      };
                      setSelected(target);
                    }
                  });
                }
              })
              .catch((error) => {
                this.$emit('error', {
                  data: error,
                  title: 'Fetch related data failed.',
                });
              });
          };
          if (typeof field.display.filter === 'function') {
            input.component.eventHandlers = {};
            input.component.eventHandlers['search-change'] = () => {
              if (typeof field.display.placeholder === 'function') {
                input.component.options.placeholder = field.display.placeholder(
                  this.getModelData(),
                );
              }
              let filter = field.display.filter(this.getModelData());
              if (filter === false) {
                input.component.options.options = [];
                return;
              }
              if (!Array.isArray(filter)) {
                filter = [filter];
              }

              const queryString = RequestQueryBuilder.create({
                filter,
              }).query();
              updateOptions(queryString);
            };
            input.component.eventHandlers['search-change']('');
          } else {
            updateOptions();
            if (field.dependentBy) {
              input.component.eventHandlers = {};
              input.component.eventHandlers['select'] = (query) => {
                let filter = field.dependentBy.filter({
                  [field.relation.name]: { id: query.value },
                });
                if (!Array.isArray(filter)) {
                  filter = [filter];
                }

                const queryString = RequestQueryBuilder.create({
                  filter,
                }).query();
                const targetField = this.model.find((entity) => {
                  return (
                    entity.display &&
                    entity.display.endpoint == field.dependentBy.endpoint
                  );
                });
                updateOptions(
                  queryString,
                  field.dependentBy.endpoint,
                  targetField,
                );
              };
            }
          }
        } else if (field.display.picklist) {
          axios
            .get('/data-api/sf-picklist', {
              params: {
                api_names: field.display.picklist,
                values_only: true,
              },
            })
            .then((response) => {
              const values = Object.values(response.data)[0].Metadata
                .customValue;
              const options = values.map((item) => item.valueName);
              input.component.options.options = options;
            });
        }
      }
    },
    sanitizeValue(value, field) {
      if (field.emptyToNull && value === '') {
        return null;
      } else if (field.type === 'json') {
        if (typeof value === 'string') {
          return JSON.parse(value);
        } else if (typeof value === 'object') {
          return JSON.stringify(value);
        }
        return value;
      } else {
        return value;
      }
    },
    async loadPreview(field, locale) {
      const relationName = field.relation.name;
      const fieldName = field.name;
      if (locale.preview.url !== null) {
        URL.revokeObjectURL(locale.preview.url);
        locale.preview.url = null;
      }
      locale.preview.loading = true;
      locale.preview.error = false;
      try {
        const { headers, data } = await axios.post(
          `/data-api/${this.entity.basePath}/preview`,
          {
            model: this.getModelData(),
            locale: locale.locale,
            fieldName,
            relationName,
          },
          { responseType: 'arraybuffer' },
        );
        const mimeType = headers['content-type'].split(';')[0];
        if (['text/html', 'application/pdf'].includes(mimeType)) {
          locale.preview.url = URL.createObjectURL(
            new Blob([data], { type: mimeType }),
          );
        }
      } catch (error) {
        locale.preview.url = URL.createObjectURL(
          new Blob([error.response?.data || error.message], {
            type: 'text/html',
          }),
        );
        locale.preview.error = true;
      }
      locale.preview.loading = false;
    },
  },
};
</script>
