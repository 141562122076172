<template>
  <div>
    <b-form-row v-for="(v, index) in model" :key="index">
      <b-col cols="1">
        <i>{{ index + 1 }}.</i>
      </b-col>
      <b-col>
        <b-form-input :value="v" @input="emit(index, $event)" />
      </b-col>
    </b-form-row>
    <b-button @click="emit(model.length, '')" size="sm" class="mr-1">
      <i class="material-icons">add</i>
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
  },
  computed: {
    model() {
      return this.value ? [...this.value] : [];
    },
  },
  methods: {
    emit(index, value) {
      const v = [...this.model];
      v[index] = value;
      this.$emit('input', v);
    },
  },
};
</script>
